<template>
	<div class="miss">
		<div class="miss-top">
			<van-icon name="warning" size="120px" color="#FF6969" />
		</div>
		<div class="miss-title">
			404
		</div>
		<div class="miss-text">
			您访问的页面不存在！
		</div>
		<div class="misss-btn">
			<van-button round type="info" block @click="$router.replace('/')">返回首页</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style lang="less" scoped>
	.miss{
		text-align: center;
		.miss-top{
			padding-top: 25%;
		}
		.miss-title{
			font-size: 30px;
			color: #000;
			margin-top: 20px;
		}
		.miss-text{
			font-size: 16px;
			color: #666;
			margin-top: 10px;
		}
		.misss-btn{
			padding: 0 40px;
			margin-top: 40px;
			.van-button{
				font-size: 18px;
			}
		}
	}
</style>